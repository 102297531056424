body {
  display: flex;
  width: 100vw;
  height: 66vh;
  justify-content: center;
  align-items: center;
  background-color: #333333;
}

main {
  display: grid;
  background-color: pink;
  border-radius: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  min-width: 300px;
  max-width: 600px;
  padding: 16px;
}

main>button {
  background-color: darkslateblue;
  color: whitesmoke;
  font-size: 2rem;
  border: 0.5rem solid pink;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
}

main>button:hover {
  color: darkslateblue;
  background-color: whitesmoke;
}

#displayArea {
  grid-row: 1 /2;
  grid-column: 1/ -1;
  text-align: right;
  margin: 0px;
  padding: 16px;
  border-radius: 16px;
  background-color: darkslategrey;
  color: lightgoldenrodyellow;
  font-size: 1.3em;
}

#clear {
  grid-row: 2 / 2;
  grid-column: 1 / 3;
}

#add {
  grid-row: 2 / 2;
  grid-column: 3 / 4;
}

#subtract {
  grid-row: 2 / 2;
  grid-column: 4/ 5;
}

#multiply {
  grid-row: 3/ 3;
  grid-column: 4/ 5;
}

#divide {
  grid-row: 4/ 4;
  grid-column: 4/ 5;
}

#equals {
  grid-row: 5 /7;
  grid-column: 4/ 5;
}

#decimal {
  grid-row: 6 /7;
  grid-column: 3 / 4;
}

#zero {
  grid-row: 6/ 7;
  grid-column: 1 / 3;
}